<template>
  <ModalContainer>
    <template #title>¿Qué son los dividendos?</template>
    <template #body>
      <div class="nn-help-modal">
        <p>
          Los dividendos no son más que una parte de los beneficios que una
          empresa decide repartir entre sus inversores y accionistas.
        </p>
        <p>
          Los dividendos son una de las formas de conseguir rendimientos
          mediante la compra de acciones.
        </p>
        <h2>¿Qué es la rentabilidad por dividendo?</h2>
        <p>
          La rentabilidad por dividendo nos indica qué cantidad de la inversión
          podemos recuperar únicamente con el reparto de dividendos de la
          compañía. Es decir indica cuánto dinero obtendrás en dividendos en
          relación con cuánto dinero has invertido en dicha compañía.
        </p>
        <p>
          Es una forma de medir la productividad de la inversión y es uno de los
          ratios financieros utilizados para valorar las acciones de una
          empresa.
        </p>
        <p>
          La rentabilidad por dividendo se calcula dividiendo los dividendos
          anuales de la compañía por el precio actual de la acción y se
          representa con un porcentaje.
        </p>
        <h2>¿Qué son los próximos dividendos?</h2>
        <p>
          Te damos información sobre cuándo y cuál será la cantidad del próximo
          dividendo que va a repartir la empresa entre sus accionistas y las
          distintas fechas relevantes: la fecha de corte para poder tener acceso
          al cobro de ese dividendo; la fecha de pago; y la fecha de ingreso de
          ese dividendo en tu cuenta de Ninety Nine.
        </p>
        <h2>¿Qué son los dividendos pasados?</h2>
        <p>
          También mostramos los dividendos que la empresa ha pagado durante el
          último año.
        </p>
        <p>
          Cuando no disponemos de información del próximo dividendo (próximo
          trimestre) o de los dividendos del último año, también te informamos
          para que lo tengas en cuenta.
        </p>
      </div>
    </template>
  </ModalContainer>
</template>

<script>
export default {};
</script>

<style></style>
